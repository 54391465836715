import React, { useContext, useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import clsx from 'clsx';
import { MdClose, MdMenu, MdSearch } from 'react-icons/md';
import HeaderStyles from '../styles/HeaderStyles';
import Logo from './Logo';
import ActionButton from './buttons/ActionButton';
import { menu } from '../constants/menu';
import { SearchModalContext } from '../contexts/searchModalContext';
import { FaCaretDown } from 'react-icons/fa';

function Header() {
  const data = useStaticQuery(graphql`
    {
      allSanityAlianza {
        nodes {
          slug { current }
          title
        }
      }
    }`
  )

  const alianzas = data.allSanityAlianza.nodes
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { openSearchModal } = useContext(SearchModalContext);

  console.log(alianzas);

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [isNavOpen]);

  const handleSearchModalOpen = () => {
    openSearchModal();
  };

  const handleNavItemClick = () => {
    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };

  return (
    <HeaderStyles>
      <div className="container">
        <div className="header__container">
          <div className="logo">
            <Logo />
          </div>
          <div className={clsx('nav__wrapper', isNavOpen && 'open')}>
            <div className="mobileIcon">
              <div className="searchIcon">
                <div
                  className="searchIcon__wrapper"
                  onClick={handleSearchModalOpen}
                  onKeyDown={handleSearchModalOpen}
                  tabIndex={0}
                  role="button"
                >
                  <MdSearch />
                </div>
              </div>
              <ActionButton
                className="mobileMenuBtn"
                onKeyDown={() => setIsNavOpen(true)}
                onClick={() => setIsNavOpen(true)}
              >
                <MdMenu />
              </ActionButton>
            </div>
            {isNavOpen && (
              <div
                aria-label="Close Menu"
                role="button"
                tabIndex={0}
                className="mobileNavBg"
                onKeyDown={() => setIsNavOpen(false)}
                onClick={() => setIsNavOpen(false)}
              />
            )}
            <nav>
              <ActionButton
                className="mobileMenuCloseBtn"
                onClick={() => setIsNavOpen(false)}
                onKeyDown={() => setIsNavOpen(false)}
              >
                <MdClose />
              </ActionButton>
              <ul>
                {menu.map((item) => {
                  if (!item.subItems) {
                    return (
                      <li key={item.path}>
                        <Link to={item.path} onClick={handleNavItemClick}>
                          {item.title}
                        </Link>
                      </li>
                    )
                  }
                  else if (item.title === "Alianzas") {
                    return <li className='item-dropdown' key={item.title}>
                        <span>{item.title} <FaCaretDown className='size-sm'></FaCaretDown></span>
                        <div className='item-dropdown-container'>
                          <div className='item-dropdown-content'>
                            {
                              alianzas.map((sub) => {
                                return (
                                  <Link to={`/alianzas/${sub.slug.current}`} onClick={handleNavItemClick}>
                                    {sub.title}
                                  </Link>
                                )
                              })
                            }
                          </div>
                        </div>
                      </li>
                  }
                })}
                <li className="searchIcon">
                  <div
                    className="searchIcon__wrapper"
                    onClick={handleSearchModalOpen}
                    onKeyDown={handleSearchModalOpen}
                    tabIndex={0}
                    role="button"
                  >
                    <MdSearch color='var(--text-1)'/>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </HeaderStyles>
  );
}

export default Header;
