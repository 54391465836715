export const menu = [
  {
    title: 'Inicio',
    path: '/',
  },
  {
    title: 'Miembros',
    path: '/miembros',
  },
  {
    title: 'Reseñas',
    path: '/posts',
  },
  {
    title: 'Alianzas',
    subItems: [{
      title: 'Proyectos',
      path: '/alianzas/proyectos'
    }]
  },
  {
    title: 'Contacto',
    path: '/contacto',
  },
];
